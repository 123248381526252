<script>
  import SearchResult from './SearchResult.svelte';
  export let label = 'Skriv søgeord';

  let search = null;
  let data = null;

  async function doSearch() {
    if (search?.length > 2) {
      const res = await fetch(
        `/Umbraco/Api/searchexamine/search?searchterm=${search}`
      );
      if (res.ok) {
        data = await res.json();
      }
    }
  }
</script>

<form
  on:submit|preventDefault={doSearch}
  novalidate
  class="top-search-form"
  role="form"
>
  <label
    for="q"
    class="d-flex m-0 p-0"
    style="z-index:-100;height:0!important;visibility:hidden!important;"
    >{label}</label
  >
  <input
    type="text"
    class="form-control"
    bind:value={search}
    placeholder={label}
    autocomplete="off"
    on:input={doSearch}
  />
</form>

<SearchResult {data} {search} />
