<script>
  export let item;

  let description =
    item?.metaList?.text?.length > 200
      ? item.metaList.text.substr(0, 200) + '...'
      : item?.metaList?.text;
</script>

<div class="search-item-description">
  {#if description}
    {description}
    <br />{/if}
  <i>{item.editorialDateFormatted}</i>
</div>
