<script>
  import ItemDescription from './ItemDescription.svelte';

  export let data;
  export let search;
</script>

{#if search?.length > 2}
  <div id="search-results">
    <div id="search-wrapper">
      {#if data === null || data.length === 0}
        <div style="margin:auto;padding:50px">
          <p>Der er ikke fundet noget der matcher '<i>{search}</i>'</p>
        </div>
      {/if}
      {#if data}
        {#each data as item, i}
          <div class="search-item"
            on:click={(window.location = item.url)}
          >
            <div class="search-item-image">
              {#if item.metaList.imageFromUdi != null}
                <img
                  src={item.metaList.imageFromUdi}
                  alt={item.metaList.headline}
                />
              {/if}
            </div>
            <div class="search-item-content">
              <div class="search-item-title">
                <a href={item.url}>{item.metaList.headline}</a>
              </div>
              <ItemDescription {item} />
            </div>
          </div>
        {/each}
      {/if}
    </div>
  </div>
{/if}
